import React, { useState } from 'react'
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import dash1Icon from '../../assets/icons/dash1.png';
import federIcon from '../../assets/icons/feeder.svg';
import transformerIcon from '../../assets/icons/transformer.svg';
import meterIcon from '../../assets/icons/meter.svg';
import chargerIcon from '../../assets/icons/charger.svg';



function GoogleMap(props) {
    // console.log("Props : ", props);
    const [map, setMap] = useState(null);

  // console.log("Markers : ", markers);
//   console.log("Center : ", center);

//   const onMapReady = (_, map) => {
//     console.log("Map ready:", map);
//     setMap(map);
//   };

  return (
    <Map
        // onReady={onMapReady}
        google={props.google}
        style={{width: props.width, height: props.height}}
        zoom={6}
        // center={{lat: props?.lat, lng: props?.lng}}
        center={{lat: props?.lat ? props?.lat :  13.32862473, lng: props?.lng ? props?.lng : 100.95155334}}
        // initialCenter={{
        //     lat: props?.lat, lng: props?.lng
        // }}
        initialCenter={{lat: props?.lat ? props?.lat : 13.32862473, lng: props?.lng ? props?.lng : 100.95155334}}
        fullscreenControl={false}
        mapTypeControl={false}
        zoomControl={false}
        scaleControl={false}
        streetViewControl={false}
        >
        <Marker 
            position={{
                lat: props?.lat, lng: props?.lng
            }}
            
            icon={{
                url: props?.type === 1 ? dash1Icon : props?.type === 2 ? federIcon : props?.type === 3 ? transformerIcon : props?.type === 4 ? meterIcon : chargerIcon,
                // anchor: new props.google.maps.Point(7.5, 10),
                // scaledSize: new props.google.maps.Size(50, 50),
            }}
            />
        {/* <Marker position={{
            lat: 13.54332638, lng: 100.26477051 
        }}/> */}
    </Map>
  )
}
export default GoogleApiWrapper({
    apiKey:'AIzaSyAJ6pz3IkaaSuapZIgBDgEYA_t2qweG6Fo'
})(GoogleMap)